<template>
  <div class="container">
    <table class="w-full" v-if="products.length > 0">
      <thead class="text-left border border-gray-500 rounded-lg">
        <tr class="text-sm">
          <th colspan="3" class="px-4 py-2 font-light">Lista Prodotti</th>
          <th class="px-4 py-2 font-light">Disattiva</th>
          <th class="px-4 py-2 font-light">Modifica</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(prod, p) in products"
          :key="p"
          class="border-b border-gray-600"
        >
          <td class="w-24">
            <img
              v-if="prod.product_photo"
              :src="prod.product_photo"
              class="w-full h-12 object-cover"
            />
          </td>
          <td>{{ prod.product_name }}</td>
          <td>{{ prod.single_unit_price | price }}</td>
          <td :class="prod.published ? 'text-secondary-500' : 'text-red-500'">
            <q-switch labelOn="On" labelOff="Off" v-model="prod.published" />
          </td>
          <td class="flex py-4">
            <router-link
              :to="`editproducts/${prod.product_id}`"
              class="text-primary-500  hover:bg-primary-500 hover:text-white p-5 rounded-md w-8 h-8 flex justify-center items-center mr-3"
            >
              <i class="mdi mdi-pencil text-2xl"></i>
            </router-link>
            <button
              class="text-red-500 hover:bg-red-500 hover:text-white p-5 rounded-md w-8 h-8 flex justify-center items-center"
              @click="deleteProduct(prod.product_id)"
            >
              <i class="mdi mdi-delete-outline text-2xl"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w-full h-full" v-else>
      <progress
        class="w-full h-12 rounded-full"
        max="100"
        :value="progressBar"
      ></progress>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import { API_URL } from "@/app-axios";
import QSwitch from "@/_components/QSwitch.vue";

export default {
  data() {
    return {
      //products: [],
      progressBar: 0,
      progressIntervall: null
    };
  },
  components: {
    QSwitch
  },
  computed: {
    ...mapGetters("auth", ["companyId", "token"]),
    ...mapGetters("products", ["products"])
  },
  async mounted() {
    // try {
    //   let options = {
    //     url: "/products",
    //     method: "get",
    //     params: {
    //       company: this.$store.state.createstore.company.company_id
    //     },
    //     headers: {
    //       "aws-access-token": this.$store.getters["auth/token"]
    //     }
    //   };
    //   console.log("Options ", options);
    //   let prods = await API_URL(options);
    //   this.SET_PRODUCTS(prods.data.content);
    //   console.log("Products ", prods);
    //   this.products = prods.data.content;
    //   //commit("SET_COMPANY_ID", this.$store.getters["auth/token"]);
    // } catch (error) {
    //   console.log("Errore loadproduct", JSON.parse(JSON.stringify(error)));
    // }
    // this.progressIntervall = setInterval(() => {
    //   this.progressBar = parseInt(Math.random() * 90);
    // }, 500);
    let cid;
    if (this.companyId) {
      cid = this.companyId;
      console.log(
        "Cerco nella company",
        this.$store.state.createstore.company.company_id
      );
    } else if (this.$store.state.createstore.company.company_id) {
      cid = this.$store.state.createstore.company.company_id;
    } else {
      return;
    }
    let p = await this.loadProductsByMerchant(cid);
    console.log("ID ", cid);
    console.log("P ", p);
  },
  // watch: {
  //   companyId: async function() {
  //     await this.loadProductsByMerchant(this.companyId);
  //   }
  // },
  beforeDestroy() {
    clearInterval(this.progressIntervall);
  },
  methods: {
    ...mapActions("products", ["loadProductsByMerchant", "deleteProduct"]),
    ...mapMutations("products", ["SET_PRODUCTS", "SET_COMPANY_ID"])
  }
};
</script>

<style lang="postcss">
tr td {
  @apply px-2;
}
progress {
  -webkit-appearance: progress-bar;
  appearance: progress-bar;
}
progress::-webkit-progress-bar {
  background: theme("colors.transparent");
}
progress::-webkit-progress-value {
  //background: theme("colors.secondary.500");
  @apply bg-gradient-to-r from-secondary-300 to-secondary-500;
  transition: width 0.15s cubic-bezier(0.75, -0.5, 0, 1.75);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125) inset;
}
progress::-webkit-progress-inner-element {
  @apply bg-transparent;
}
</style>
